@import '~primeng/resources/themes/saga-blue/theme.css';

:root {
  --primary-color: #0659a9;
  --blue-50: #e1f6ff;
  --blue-100: #b3e6ff;
  --blue-200: #80d6ff;
  --blue-300: #4bc6ff;
  --blue-400: #1dbaff;
  --blue-500: #00adff;
  --blue-600: #009ef4;
  --blue-700: #008bdf;
  --blue-800: #007acb;
  --blue-900: #0659a9;
  --green-50: #e5f8eb;
  --green-100: #c0edcd;
  --green-200: #95e1ad;
  --green-300: #62d68a;
  --green-400: #2ecc70;
  --green-500: #00c255;
  --green-600: #00b24b;
  --green-700: #009f3e;
  --green-800: #008e32;
  --green-900: #006e1e;
}
